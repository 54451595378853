/*
 * workaround for materialize-css:
 * we want to use the validate class for CSS,
 * but we don't want the automatic validation behavior,
 * so we copy here all rules that involve the "validate" class, renamed to "my-validate"
 */
input:not([type]).my-validate + label,
input[type=text].my-validate + label,
input[type=password].my-validate + label,
input[type=email].my-validate + label,
input[type=url].my-validate + label,
input[type=time].my-validate + label,
input[type=date].my-validate + label,
input[type=datetime].my-validate + label,
input[type=datetime-local].my-validate + label,
input[type=tel].my-validate + label,
input[type=number].my-validate + label,
input[type=search].my-validate + label,
input[type=checkbox].my-validate + label,
textarea.materialize-textarea.my-validate + label,
.datepicker.my-validate + .picker + label {
  width: 100%;
  pointer-events: none;
}
.input-field.col .prefix ~ .my-validate ~ label {
  width: calc(100% - 3rem - 1.5rem);
}
.input-field .prefix ~ .my-validate ~ label {
  margin-left: 3rem;
  width: 92%;
  width: calc(100% - 3rem);
}
.input-field label {
  left: 0;
}
.input-field label:after {
  transform: translateY(-120%);
}
.input-field label.active:after {
  transform: translateY(0);
}
.select-wrapper + label {
  min-width: 50%;
}
.select-wrapper + label:after {
  content: attr(data-error);
  color: #F44336;
  display: block;
  position: absolute;
  top: 80px;
}
input.datepicker + .picker + label:after {
  display: block;
  content: "";
  position: absolute;
  top: 60px;
  opacity: 0;
  transition: 0.2s opacity ease-out, 0.2s color ease-out;
}
input.datepicker.invalid + .picker + label:after {
  content: attr(data-error);
  color: #F44336;
  opacity: 1;
  width: 500px;
}
.invalid + label:after,
.invalid + .picker + label:after {
  font-size: 1rem;
}
.collapsible > li .collapsible-body {
  display: block;
}
.form-autocomplete-select .autocomplete-content {
  position: absolute;
}
body input[type=text]:focus:not([readonly]).invalid {
  border-bottom: 1px solid #F44336;
  box-shadow: 0 1px 0 0 #F44336;
}
.picker {
  outline: none;
}
.material-icons {
  max-width: 1ch;
  overflow: hidden;
}
.main-title-color {
  color: #283593;
}
.spinner-color {
  border-color: #283593;
}
.main-background-color {
  background-color: #283593;
}
.main-text-color {
  color: #FFFFFF !important;
}
.light-background-color {
  background-color: #EEE;
}
.danger-text-color {
  color: #B71C1C !important;
}
.light-danger-background-color {
  background-color: #FFEBEE !important;
}
.dark-button-text {
  color: #283593;
}
.light-text {
  color: #9E9E9E;
}
.validation-error-text-color {
  color: #F44336 !important;
}
.preloader-wrapper-center.preloader-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.preloader-wrapper-center.preloader-wrapper .spinner-layer {
  border-color: #283593;
}
h1 {
  color: #283593;
}
body h1 {
  font-size: 2.2rem;
}
h3 {
  color: #283593;
}
body h3 {
  font-size: 1.2rem;
}
strong {
  font-weight: bold;
}
nav.navigation {
  background-color: #283593;
}
button.btn:hover.primary,
button:focus.primary {
  background-color: #283593;
}
button.btn:hover.secondary,
button:focus.secondary {
  background-color: #EEE;
}
button.primary {
  background-color: #283593;
}
button.secondary {
  background-color: #EEE;
  color: #9E9E9E;
}
button.btn-flat {
  background-color: #EEE;
}
.remove > button.btn-flat,
.remove > button.primary {
  background-color: #FFEBEE !important;
  color: #343434;
}
input[type="checkbox"]:checked + label:before {
  border-color: #00C853;
}
input[type="checkbox"].filled-in:checked + label:after,
input[type="checkbox"].filled-in.tabbed:checked:focus + label:after {
  border-color: #00C853;
  background-color: #00C853;
}
.add-entity {
  position: fixed;
  right: 4em;
  bottom: 3em;
  z-index: 2;
}
@media (max-width: 600px) {
  body .add-entity {
    right: 1em;
    bottom: 1em;
  }
}
@media (min-width: 801px) {
  .small-screen-caption {
    display: none;
    visibility: hidden;
  }
}
@media (max-width: 800px) {
  .small-screen-caption {
    display: inline-block;
    font-weight: bold;
    width: 8em;
  }
  list-loader table th {
    display: none;
  }
  body .container > list-loader.uncontainer > .list-loader-content > table td,
  body .container > list-loader.uncontainer > .list-loader-content > table td:first-child,
  body .container > list-loader.uncontainer > .list-loader-content > table td:last-child {
    padding: 10px 1rem;
  }
  body .container > .uncontainer {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  body .container > list-loader.uncontainer {
    padding-left: 0;
    padding-right: 0;
  }
  body .container > list-loader.uncontainer > *,
  body .container > list-loader.uncontainer td:first-child,
  body .container > list-loader.uncontainer td:last-child,
  body .container > list-loader.uncontainer th:first-child,
  body .container > list-loader.uncontainer th:last-child {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  body .container > list-loader.uncontainer > .list-loader-content {
    padding-left: 0;
    padding-right: 0;
  }
  .list-loader-content > table td.actions-cell {
    margin-left: -3px;
  }
  list-loader table td {
    display: block;
    padding: 10px 5px;
  }
  list-loader table td > * {
    display: inline-block;
  }
  list-loader table td:last-child:before {
    display: none;
    width: 0;
  }
  list-loader table .row-with-info > td:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
  list-loader table tr:last-child > td:last-child {
    border-bottom: none;
  }
}
.no-results {
  font-style: italic;
  font-size: 1.2em;
}
i.action {
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
i.action.action-remove {
  color: #B71C1C !important;
}
.missing-value {
  font-style: italic;
  color: #9E9E9E;
}
.form-section-title {
  font-size: 1.2em;
  margin: 1em 0;
  color: #283593;
}
form {
  max-width: 600px;
}
form .checkbox-container {
  padding: 1em 0;
}
form .inline-input > div.input-field,
form .inline-input .checkbox-container {
  display: inline-block;
}
form .validation-error {
  color: #F44336 !important;
  margin-top: 1em;
}
form .collapsible-body {
  padding: 1em;
}
form .collapsible-header-with-actions {
  line-height: 1rem;
  padding: 1rem;
}
form .attribute-meta-actions {
  float: right;
  margin-top: -1rem;
}
form .info {
  margin: 0 24px;
}
form .add-attribute {
  margin: 1em 24px;
}
form .copy-dimensions {
  display: block;
  margin: 1em 24px;
}
form li.error .collapsible-header,
form li.error .collapsible-header .missing-value {
  color: #F44336 !important;
}
form .input-field label {
  white-space: nowrap;
}
.form-location-map-line i.clear {
  color: #9E9E9E;
  line-height: 3rem;
  cursor: pointer;
  margin-left: 4px;
}
.form-autocomplete-select {
  margin-top: 1em;
  margin-bottom: 0.5em;
}
.form-autocomplete-select .select-title {
  font-size: 1.2em;
}
.form-autocomplete-select .input-field {
  display: flex;
}
.form-autocomplete-select .autocomplete-content {
  margin-top: 3.5rem;
  left: 0;
}
.form-autocomplete-select label.active > i {
  display: none;
}
.form-autocomplete-select i.left {
  margin-right: 5px;
  margin-top: -3px;
}
.form-autocomplete-select i.clear {
  color: #9E9E9E;
  line-height: 3rem;
  cursor: pointer;
}
.form-autocomplete-select .form-select-search-action > span {
  color: #757575;
  font-style: italic;
}
.form-autocomplete-select .loading-results {
  padding: 0.5em;
  overflow-y: hidden;
}
.form-autocomplete-select .more-results :hover {
  background-color: white;
  cursor: default;
}
.form-autocomplete-select .more-results > span {
  color: #9E9E9E;
  font-style: italic;
}
.form-autocomplete-select .selected-entities {
  margin-top: -10px;
  margin-bottom: 1em;
}
.form-autocomplete-select .selected-entities > li > div {
  display: inline-block;
}
.form-autocomplete-select .selected-entities span.action {
  display: block;
  min-height: 36px;
  margin-top: 5px;
}
.form-autocomplete-select .selected-entities span.action > div {
  margin-left: 32px;
}
.form-input-line {
  display: flex;
  align-items: center;
}
.form-input-line > * {
  margin-right: 2em;
}
.form-input-line > *:last-child {
  margin-right: 0;
}
.form-input-line > i.action {
  flex: 0;
  margin-right: 1em;
}
.form-input-line > .no-shrink {
  flex-shrink: 0;
}
.form-input-line > form-select-search > .form-autocomplete-select {
  margin-bottom: 0;
}
.form-input-line > * {
  flex: 1;
}
.form-input-line > *.dont-expand {
  flex: initial;
}
.form-buttons {
  letter-spacing: 1em;
}
.form-buttons .validation-error {
  letter-spacing: normal;
  margin: 0 0 1em;
}
@media (max-width: 600px) {
  form .form-input-line.form-input-line-breakable {
    display: block;
  }
  form .form-input-line.form-input-line-breakable > * {
    margin-right: 0;
  }
}
.compact-form {
  display: flex;
  max-width: inherit;
  flex-wrap: wrap;
}
.compact-form > * {
  margin-right: 2em;
}
.compact-form .order-by {
  margin-right: auto;
}
.compact-form .form-autocomplete-select {
  margin-top: 0;
}
.compact-form .form-autocomplete-select .input-field,
.compact-form form-text-input .input-field,
.compact-form form-date-input .input-field {
  display: inline-flex;
}
.compact-form .form-autocomplete-select .input-field label,
.compact-form form-text-input .input-field label,
.compact-form form-date-input .input-field label {
  top: 0;
}
.compact-form .form-autocomplete-select .input-field label.active,
.compact-form form-text-input .input-field label.active,
.compact-form form-date-input .input-field label.active {
  top: 0.8rem;
}
.compact-form .form-autocomplete-select .input-field i.clear,
.compact-form form-text-input .input-field i.clear,
.compact-form form-date-input .input-field i.clear {
  line-height: 2rem;
}
.compact-form .form-autocomplete-select .autocomplete-content {
  margin-top: 2.5rem;
}
.compact-form input,
.compact-form .select-wrapper input.select-dropdown {
  height: 2rem;
  line-height: 2rem;
  min-width: 15rem;
}
.compact-form label > i {
  margin-right: 5px;
}
.compact-form input:not([type]) + label:after,
.compact-form input[type=text] + label:after {
  top: 46px;
}
form-date-time-input {
  display: flex;
}
form-date-time-input > * {
  margin-right: 1em;
}
form-date-time-input > *:last-child {
  margin-right: 0;
}
input[type=text]:focus:not([readonly]).no-matching-results,
input.no-matching-results {
  border-bottom: 1px solid #F44336;
  box-shadow: 0 1px 0 0 #F44336;
}
input[type=text]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type=text]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
body input:not([type]):focus:not([readonly]),
.input-field input:not([type]):focus:not([readonly]),
.input-field input[type=text]:focus:not([readonly]),
.input-field input[type=password]:focus:not([readonly]),
.input-field textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #00C853;
  box-shadow: 0 1px 0 0 #00C853;
}
.input-field input:not([type]):focus:not([readonly]) + label,
.input-field input[type=text]:focus:not([readonly]) + label,
.input-field input[type=password]:focus:not([readonly]) + label,
.input-field textarea.materialize-textarea:focus:not([readonly]) + label {
  color: #00C853;
}
body .dropdown-content li > a,
body .dropdown-content li > span {
  color: #00C853;
}
.input-field i.prefix.active {
  color: #00C853;
}
.input-field input:not([type]).valid,
.input-field input[type=text].valid,
.input-field input[type=password].valid,
.input-field textarea.materialize-textarea.valid {
  border-bottom: 1px solid #9E9E9E;
  box-shadow: none;
}
.input-field input:not([type]):focus.valid,
.input-field input[type=text]:focus.valid,
.input-field input[type=password]:focus.valid,
.input-field textarea.materialize-textarea:focus.valid {
  border-bottom: 1px solid #00C853;
  box-shadow: none;
}
body [type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:after {
  background-color: #00C853;
}
body [type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:before,
[type="radio"].with-gap:checked + label:after {
  border: 2px solid #00C853;
}
body [type="radio"]:not(:checked) + label:before,
[type="radio"]:not(:checked) + label:after {
  border: 2px solid #00C853;
}
form-date-input .picker__day.picker__day--today,
form-date-time-input .picker__day.picker__day--today {
  color: #00C853;
}
form-date-input .picker__day--selected,
form-date-time-input .picker__day--selected,
form-date-input .picker__day--selected:hover,
form-date-time-input .picker__day--selected:hover,
form-date-input .picker--focused .picker__day--selected,
form-date-time-input .picker--focused .picker__day--selected {
  background-color: #00C853;
}
form-date-input .picker__date-display,
form-date-time-input .picker__date-display,
form-date-input .picker__weekday-display,
form-date-time-input .picker__weekday-display {
  background-color: #283593;
}
form-date-input .picker__today,
form-date-time-input .picker__today,
form-date-input .picker__close,
form-date-time-input .picker__close {
  background-color: #EEE;
  color: #283593;
}
.container .collection a.collection-item {
  color: #283593;
}
span.no-matching-results {
  color: #F44336 !important;
  font-style: italic;
}
.tour-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.3);
}
.tour-highlight {
  position: relative;
  z-index: 100000;
  pointer-events: none;
}
#horizontal-nav-trials.tour-highlight,
#nav-menu.tour-highlight,
#horizontal-nav-invite.tour-highlight {
  background-color: #283593;
}
#first-trial-row-name.tour-highlight,
#first-trial-row-collected.tour-highlight {
  background-color: white;
}
.chart-group.tour-highlight {
  pointer-events: all;
}
#define-first-trial.tour-highlight {
  background-color: white;
}
#add-trial.tour-highlight {
  position: fixed;
}
div.hopscotch-bubble .hopscotch-bubble-container {
  z-index: 100000;
  background-color: white;
}
popup {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.3);
}
popup .popup-content {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.25s;
  padding: 20px;
  margin: 0.5rem 0 1rem 0;
  border-radius: 2px;
  background-color: #fff;
  position: fixed;
  top: 10%;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 2em;
  max-height: 80%;
  overflow-y: auto;
  max-width: 600px;
  width: 90%;
  text-align: center;
}
popup .popup-content > h1 {
  font-size: 2rem;
  margin: 0;
}
popup .popup-content .popup-buttons {
  margin-top: 2em;
}
popup .popup-content .popup-buttons secondary-button {
  margin-left: 1em;
}
popup.big .popup-content {
  max-width: initial;
}
@media (max-width: 800px), (max-height: 800px) {
  body popup .popup-content {
    padding: 1em;
    max-height: 90%;
    width: 95%;
    top: 5%;
  }
}
.server-error-popup h1 {
  color: #F44336 !important;
}
loading-indicator {
  display: flex;
  align-items: center;
}
loading-indicator .spinner-layer {
  border-color: #ddd;
}
loading-indicator > span {
  margin-left: 1em;
  font-style: italic;
  font-size: 1.2em;
  color: #9E9E9E;
}
loading-indicator.fill-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  justify-content: center;
  z-index: 1;
}
.load-more-results {
  display: block;
  margin-top: 2em;
}
flat-button {
  outline: none;
}
list-loader a {
  color: #283593;
}
list-loader .anchor-with-link {
  display: inline-block;
}
list-loader flat-button > span.action {
  margin-bottom: 5px;
  color: #757575;
}
body list-loader .dropdown-content li > a {
  color: #757575;
}
list-loader .list-loader-content > table > thead,
list-loader .list-loader-content > table > tbody > tr {
  border-bottom: 1px solid #d0d0d0;
  border-top: 1px solid #d0d0d0;
}
.container > .uncontainer {
  display: block;
  margin-left: -5.55555556%;
  margin-right: -5.55555556%;
  padding-left: 2rem;
  padding-right: 2rem;
}
.container > list-loader.uncontainer {
  padding-left: 0;
  padding-right: 0;
}
.container > list-loader.uncontainer > * {
  padding-left: 2rem;
  padding-right: 2rem;
}
.container > list-loader.uncontainer .list-loader-content {
  padding-left: 0;
  padding-right: 0;
}
.container > list-loader.uncontainer .list-loader-content td:first-child,
.container > list-loader.uncontainer .list-loader-content td:last-child,
.container > list-loader.uncontainer .list-loader-content th:first-child,
.container > list-loader.uncontainer .list-loader-content th:last-child {
  padding-left: 2rem;
  padding-right: 2rem;
}
list-loader.uncontainer {
  margin-bottom: 6em;
}
@media only screen and (min-width: 601px) {
  .container > .uncontainer {
    display: block;
    margin-left: -8.82352941%;
    margin-right: -8.82352941%;
  }
}
@media only screen and (min-width: 993px) {
  .container > .uncontainer {
    display: block;
    margin-left: -21.42857143%;
    margin-right: -21.42857143%;
  }
}
flat-button > span {
  margin-right: 1em;
}
flat-button.remove > span.action {
  color: #B71C1C !important;
}
flat-button.no-margin > span.action > div {
  margin-left: 0;
  margin-right: 0;
}
.info {
  color: #9E9E9E;
  font-style: italic;
}
.info > i {
  margin-right: 0;
}
.info > div {
  margin-left: 2.5em;
  margin-bottom: 2em;
}
span.action {
  display: inline-block;
  line-height: 36px;
  cursor: pointer;
  color: #00C853;
}
span.action > i {
  margin-right: 5px;
  line-height: inherit;
}
span.action > div {
  margin-left: 32px;
  line-height: inherit;
}
span.action.remove {
  color: inherit;
}
span.action.remove > i {
  color: #B71C1C !important;
}
table th > i.material-icons {
  display: none;
  font-size: 20px;
  margin-right: 5px;
}
table th {
  color: #757575;
}
table th.is-sorted {
  color: inherit;
}
table th.is-sorted > i.material-icons {
  display: inline-block;
}
table actions-menu flat-button > .action {
  width: auto;
  margin: 0;
}
table td.actions-cell {
  position: relative;
  line-height: 3em;
  padding: 5px;
}
table td.actions-cell actions-menu .dropdown-content {
  top: 5px;
  right: 5px;
  min-width: 150px;
}
table .row-with-info > td {
  padding-bottom: 0;
}
table .row-info > td {
  padding: 10px 0 1em 0;
  font-size: 12px;
  color: #9E9E9E;
}
table .row-info > td > span {
  display: inline-block;
  margin-right: 2em;
  margin-bottom: 5px;
}
table .row-info > td > span i.material-icons {
  font-size: 20px;
  margin-right: 5px;
}
list-loader tr.row-with-info {
  border-bottom: none;
}
list-loader tr.row-info {
  border-top: none;
}
.customize {
  display: inline-block;
  margin-bottom: 1em;
}
.loosen-text {
  margin-top: 2em;
  margin-bottom: 2em;
}
.actions-menu-container {
  position: relative;
}
actions-menu {
  float: right;
}
actions-menu .dropdown-content {
  top: 0;
  right: 0;
  opacity: 1;
  display: block;
}
actions-menu a.remove {
  color: #B71C1C !important;
}
body .progress {
  background-color: rgba(0, 200, 83, 0.2);
}
body .progress .determinate {
  background-color: #00C853;
}
forms-stack popup {
  z-index: 99998;
}
forms-stack popup .popup-content {
  text-align: left;
}
forms-stack nav {
  box-shadow: none;
  background-color: transparent;
}
forms-stack nav .breadcrumb {
  color: #9E9E9E;
}
forms-stack nav .breadcrumb:last-child {
  color: #283593;
}
forms-stack nav .breadcrumb:before {
  color: #9E9E9E;
}
forms-stack h1 {
  display: none;
}
forms-stack form.card {
  margin: 0;
  box-shadow: none;
}
forms-stack form.card .card-content {
  padding: 0 0 20px 0;
}
forms-stack form.card .card-action {
  padding: 20px 0;
}
forms-stack .picker--opened .picker__holder {
  background: none;
}
body .card-tabs {
  background-color: #EEE;
  box-shadow: -2px 0px 2px rgba(0, 0, 0, 0.14), 2px -2px 2px rgba(0, 0, 0, 0.06);
  display: flex;
}
.tabs-decorator {
  display: flex;
  align-items: center;
  border-left: 1px solid #d0d0d0;
  padding-left: 0.5em;
}
.tabs-decorator .new {
  color: #757575;
  margin-right: 0.5em;
  cursor: pointer;
}
body .tabs {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  height: auto;
  border-bottom: 1px solid #EEE;
  background-color: inherit;
}
body .tabs .tab {
  height: initial;
  text-align: left;
}
body .tabs .tab.error > a {
  color: #F44336;
}
body .tabs .tab.active {
  border-bottom: 2px solid #283593;
}
body .tabs .tab.active > a {
  color: #283593;
}
body .tabs .tab .input-field {
  margin-top: 0;
}
body .tabs .tab .input-field label {
  position: relative;
}
body .tabs .tab .input-field label.active {
  font-size: inherit;
}
body .tabs .tab .input-field label.active:after {
  transform: translateY(-120%);
}
body .tabs .tab .input-field input[type="text"] {
  height: 2em;
  background-color: white;
  padding: 0 0.5em;
  width: auto;
  margin: 0;
  border-bottom: none;
  box-shadow: none;
}
body .tabs .tab .input-field input[type="text"].invalid + label:after {
  text-transform: initial;
  top: initial;
  white-space: normal;
  line-height: initial;
  text-align: left;
  position: relative;
  margin-top: 0.5em;
}
body .tabs .tab a {
  color: #9E9E9E;
  display: flex;
  font-weight: bold;
}
body .tabs .tab a:hover {
  color: #9E9E9E;
  cursor: pointer;
}
body .tabs .tab a i {
  line-height: inherit;
}
@media only screen and (max-width: 992px) {
  body .tabs .tab {
    flex: initial;
  }
}
.dialog-title {
  color: #283593;
  font-weight: 400;
  font-size: 2em;
}
.dialog-title-select-dataset {
  margin-bottom: 1em;
}
form-select.allow-empty .dropdown-content li.selected:first-child {
  background-color: inherit;
}
form-select.allow-empty .dropdown-content li:first-child > span {
  color: #9E9E9E;
}
.allow-empty.empty input {
  color: #9E9E9E;
}
.break-margin-collapse {
  overflow: hidden;
  height: 0;
  width: 0;
}
.form-lines li {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.form-lines li > * {
  margin-right: 0.5em;
  margin-left: 0.5em;
}
.form-lines li > :first-child {
  width: 250px;
}
.form-lines li flat-button {
  align-self: center;
}
.uncontainer.list-loader-actions {
  float: right;
  display: flex;
}
.uncontainer.list-loader-actions loading-indicator {
  margin-right: 1em;
  font-size: 0.9em;
}
.main-logo {
  height: 128px;
  width: 100%;
}
p.motto {
  margin: 2rem 0 2rem;
  font-size: 28px;
  font-weight: 300;
  max-width: 400px;
}
@media (min-width: 801px) {
  table .numeric {
    text-align: right;
  }
}
form-textarea .validation-error {
  margin-top: -1em;
}
html,
body {
  height: 100%;
}
body {
  display: flex;
  flex-direction: column;
}
.container-full {
  flex: 1 0 auto;
}
.select-validation-error {
  margin-bottom: 2rem;
  margin-top: -1rem;
  color: #F44336;
}
.imported-icon {
  color: #4caf50;
}
.imported-info {
  display: flex;
  align-items: center;
  margin-bottom: 2em;
  color: #4caf50;
}
.imported-info > i {
  margin-right: 10px;
}
.checkboxes-title {
  color: #9E9E9E;
  margin: 1em 0 0;
}
form .compact-checkbox .checkbox-container {
  padding: 0.2em 0;
}
.text-area-autoresize-container {
  position: relative;
}
.text-area-autoresize-container > iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border: none;
  z-index: -1;
}
.action-button {
  display: flex;
  justify-content: space-between;
  padding: 0;
  border: none;
  background: none;
}
.action-button :first-child {
  line-height: 20px;
}
.action-button :last-child {
  font-size: 10px;
  line-height: 20px;
}
.action-button:focus {
  background: none;
}
.login {
  max-width: 500px;
  margin: 0 auto;
  padding-top: 1em;
  padding-bottom: 108px;
  font-size: 18px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login .secondary-links {
  border-top: 1px solid #414141;
  margin-top: 4rem;
  padding-top: 1rem;
  display: flex;
  justify-content: center;
  width: 100%;
}
.login .secondary-links > a {
  color: #283593;
  font-size: 20px;
}
.login .select-team-title {
  font-size: 1.2em;
  margin-bottom: 1em;
  margin-top: 1em;
}
.login a.collection-item {
  text-align: left;
}
.login #sign-in {
  display: inline-block;
}
@media (max-height: 600px) {
  body .login p {
    margin: 3rem 0;
    font-size: 24px;
  }
  body .login .secondary-links {
    margin-top: 3rem;
  }
}
@media (max-height: 510px) {
  body .login {
    padding-bottom: 1em;
  }
  body .login .footer {
    display: none;
  }
}
list-filters {
  display: inline-block;
}
list-filters .filters {
  border-radius: 4px;
  background-color: rgba(40, 53, 147, 0.1);
  padding: 1em 0 0 1em;
  display: flex;
  flex-wrap: wrap;
}
list-filters .filters .picker__input.picker__input--active {
  border-color: #9E9E9E;
}
list-filters .filters form-date-input .input-field {
  display: block;
}
list-filters .filters > div {
  position: relative;
  margin: 0 1em 1em 0;
}
list-filters .filters > div:last-child {
  min-width: 160px;
}
list-filters .filters > div .input-field {
  margin: 0;
}
list-filters .filters > div .input-field input[type="text"] {
  height: 24px;
  margin: 0;
}
list-filters .filters > div > div {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #9E9E9E;
  justify-content: space-between;
  cursor: pointer;
}
list-filters .filters > div > div.selected {
  border-bottom: 1px solid #283593;
}
list-filters .filters > div > div > i {
  color: #9E9E9E;
  margin-left: 1em;
}
list-filters .filters > div > div > i[data-icon="check"] {
  color: #283593;
}
list-filters .filters .results-popup {
  min-width: 320px;
  position: absolute;
  background-color: white;
  z-index: 1;
  padding-top: 0.5em;
  border: 1px solid #d0d0d0;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
}
list-filters .filters .results-popup loading-indicator {
  margin: 0 0.5em 0.5em;
}
list-filters .filters .results-popup loading-indicator > span {
  font-size: 1em;
}
list-filters .filters .results-popup .preloader-wrapper.small {
  height: 24px;
  width: 24px;
}
list-filters .filters .results-popup .search-input {
  display: flex;
  align-items: center;
  padding-right: 24px;
  margin: 0 0.5em;
  width: 100%;
}
list-filters .filters .results-popup .search-input > input {
  width: 100%;
  padding-right: 24px;
  margin-bottom: 1em;
  height: 2.5rem;
  border-bottom: 1px solid #757575;
  box-shadow: none;
}
list-filters .filters .results-popup .search-input > i {
  height: 2.9rem;
  margin-left: -24px;
}
list-filters .filters .results-popup .search-results {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  padding: 0 0.5em;
}
list-filters .filters .results-popup .search-results > div {
  display: flex;
  margin-bottom: 0.5em;
  align-items: center;
}
list-filters .filters .results-popup .search-results > div > i {
  margin-right: 5px;
}
list-filters .filters .results-popup .search-results > div > i[data-icon="check_box"],
list-filters .filters .results-popup .search-results > div > i[data-icon="radio_button_checked"] {
  color: #283593;
}
list-filters .filters .results-popup .search-results .more-results {
  color: #9E9E9E;
  font-style: italic;
}
list-filters .filters .results-popup .save-container {
  width: 100%;
  padding: 0 0.5em;
}
list-filters .filters .results-popup .save {
  cursor: pointer;
  background-color: #283593;
  border-radius: 4px;
  color: white;
  padding: 0.5em;
  text-align: center;
  text-transform: uppercase;
  display: block;
  margin: 0.5em 0;
}
list-filters .selections {
  margin-top: 1em;
}
list-filters .selections > div {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 0.2em 0.5em;
  border: 1px solid #d0d0d0;
  border-radius: 2em;
  margin: 0 0.5em 0.5em 0;
}
list-filters .selections > div > span {
  margin-right: 1em;
}
.list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.list-header loading-indicator .preloader-wrapper.small {
  width: 24px;
  height: 24px;
}
.list-header loading-indicator > span {
  display: none;
}
.list-header .list-header-actions {
  display: flex;
  align-items: flex-end;
}
.list-header .list-header-actions > * {
  cursor: pointer;
  color: #757575;
  margin-right: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.list-header .list-header-actions > *:last-child {
  margin-right: 0;
}
@media (max-width: 600px) {
  .list-header {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2em;
  }
}
.list-global-actions {
  margin-bottom: 1em;
}
.filter-title {
  color: #00C853;
}
.filter-summary {
  margin-bottom: 1em;
}
.filter-summary .filter-value {
  font-size: 0.8em;
}
.filter-summary .filter-value.not-set {
  color: #9E9E9E;
}
.filter-widget {
  display: flex;
  flex-direction: column;
}
list-loader.list-loader-left-filters > div {
  display: flex;
}
list-loader.list-loader-left-filters form.compact {
  flex-direction: column;
}
list-loader.list-loader-left-filters form.compact .filters-title {
  display: block;
  color: #283593;
  font-size: 1.2em;
  margin-bottom: 1em;
}
list-loader .filters-title {
  display: none;
}
list-loader form.compact {
  flex-direction: row;
}
list-loader form.compact input {
  width: 150px;
  min-width: inherit;
}
list-loader form.compact .input-field.input-field-select {
  margin-top: 1rem;
}
list-loader form.compact.no-sorting.new-filters {
  justify-content: flex-end;
}
list-loader form.compact.new-filters {
  align-items: flex-start;
  flex-wrap: nowrap;
}
list-loader form.compact.new-filters > list-filters {
  margin-right: 0;
  margin-left: 1em;
}
list-loader .list-loader-content {
  flex: 1;
}
@media (max-width: 600px) {
  list-loader form.compact.new-filters {
    flex-wrap: wrap;
  }
  list-loader form.compact.new-filters > list-filters {
    margin-left: 0;
  }
}
@media (max-width: 800px) {
  list-loader.list-loader-left-filters > div {
    flex-direction: column;
  }
}
.uncontainer.list-loader-actions {
  float: right;
  display: flex;
}
.uncontainer.list-loader-actions loading-indicator {
  margin-right: 1em;
  font-size: 0.9em;
}
.uncontainer-flat-btn {
  float: right;
}
.list-item-public-access {
  background-color: #f0f0f0;
}
.navigation-bar {
  background-color: #283593;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.navigation-bar #header-left {
  display: flex;
  align-items: center;
  color: white;
}
.navigation-bar #header-logo {
  height: 64px;
  width: 64px;
  margin-left: 15px;
  margin-right: 15px;
}
.navigation-bar .navigation-bar-banner {
  color: white;
  flex: 1;
  text-align: center;
  text-transform: uppercase;
}
.navigation-bar ul {
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.navigation-bar ul li {
  transition: background-color 0.3s;
  display: none;
}
.navigation-bar ul li.important,
.navigation-bar ul li.expand {
  display: inline-block;
}
.navigation-bar ul li a {
  color: #FFFFFF !important;
  transition: background-color 0.3s;
  font-size: 1rem;
  padding: 1.2rem 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.navigation-bar ul li a i {
  max-width: 1ch;
}
.navigation-bar ul .selectable:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
}
.navigation-bar ul.vertical-menu {
  display: none;
}
.navigation-bar.vertical-menu-open ul.vertical-menu {
  background-color: #283593;
  position: fixed;
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;
  z-index: 100000;
  animation: slide-in 0.2s forwards;
  min-width: 250px;
}
.navigation-bar.vertical-menu-open ul.vertical-menu li {
  display: block;
}
.navigation-bar.vertical-menu-open ul.vertical-menu li.expand {
  display: none;
}
.navigation-bar.vertical-menu-open ul.vertical-menu li a,
.navigation-bar.vertical-menu-open ul.vertical-menu li .nav-group-title {
  padding: 0.5rem 15px;
}
.navigation-bar.vertical-menu-open ul.vertical-menu li .nav-group-title {
  color: white;
}
.navigation-bar.vertical-menu-open ul.vertical-menu li .nav-group-item {
  padding-left: 2rem;
}
.navigation-bar.vertical-menu-open ul.vertical-menu li.nav-group-expanded {
  background-color: rgba(0, 0, 0, 0.05);
}
.navigation-bar.vertical-menu-open .navigation-bar-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.3);
}
.navigation-bar .material-icons {
  color: #424242;
}
@media (max-width: 1200px) {
  body .navigation-bar ul > li.important {
    display: none;
  }
  body .navigation-bar.vertical-menu-open ul.vertical-menu > li.important {
    display: block;
  }
}
@media print {
  .navigation-bar {
    display: none;
  }
}
@keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}
@-webkit-keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}
@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@-webkit-keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.need-page-reload {
  width: 100%;
  padding: 5px;
  background-color: #FFEB3B;
  text-align: center;
}
.need-page-reload a {
  cursor: pointer;
  margin-left: 1em;
}
.need-page-reload a:first-child {
  margin-left: 0;
}
.need-page-reload .prefer-nowrap {
  display: inline-block;
}
.navigation-bar-right {
  display: flex;
  align-items: baseline;
}
.navigation-bar-notifications {
  color: white;
  position: relative;
  padding: 4px;
  cursor: pointer;
}
.navigation-bar-badge {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;
  border-radius: 50%;
  background-color: #F44336;
  width: 15px;
  height: 15px;
  text-align: center;
  line-height: 15px;
}
.navigation-bar-notifications-popup {
  position: absolute;
  width: 200px;
  left: -148px;
  z-index: 9999;
}
.navigation-bar-notifications-popup-content {
  margin-top: 11px;
  background-color: white;
  padding: 0.5em;
  border: 1px solid #444;
}
.navigation-bar-notifications-popup-content > a {
  display: block;
  color: rgba(0, 0, 0, 0.87);
  outline: none;
}
.navigation-bar-notifications-popup-content > a:hover {
  background-color: #EEE;
}
.navigation-bar-notifications-popup-content > a > :first-child {
  font-weight: bold;
}
.navigation-bar-notifications-separator {
  border-bottom: 1px solid #d0d0d0;
}
.navigation-bar-arrow-top {
  right: 24px;
  position: absolute;
  display: block;
  content: " ";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 12px 12px 12px;
  border-color: transparent transparent #444 transparent;
}
.navigation-bar-arrow-top::after {
  display: block;
  content: " ";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 12px 12px 12px;
  border-color: transparent transparent white transparent;
  position: absolute;
  left: -12px;
  top: 1px;
  z-index: 3;
}
.dialog-remove .dialog-title {
  color: #B71C1C;
}
.dialog-remove dt {
  font-weight: bold;
}
.dialog-remove dd {
  margin-bottom: 0.5em;
  margin-left: 1em;
}
.dialog-remove button.primary,
.dialog-remove button.primary.btn:hover,
.dialog-remove button.primary:focus {
  background-color: #B71C1C;
}
.i18n-text-input .language-extra-translations {
  margin-left: 3em;
  margin-bottom: 2em;
}
.i18n-text-input .language-row {
  display: flex;
  align-items: baseline;
}
.i18n-text-input .language-row .input-field,
.i18n-text-input .language-row form-text-input {
  flex: 1;
  margin-right: 1em;
}
.i18n-text-input .language-row .language-info {
  text-transform: uppercase;
  color: #9E9E9E;
}
.i18n-text-input .language-row flat-button .material-icons {
  font-size: 14px;
}
.i18n-text-input .language-row flat-button .action div {
  margin-left: 20px;
  text-transform: uppercase;
  font-size: 0.8em;
}
.i18n-text-input .language-row flat-button > span {
  margin-right: 0;
}
.file-upload .file-field {
  display: inline-block;
}
.file-upload .file-field span.action {
  position: relative;
}
.file-upload .file-field input[type=file] {
  font-size: 0;
  height: 100%;
}
.file-upload-img {
  max-width: 300px;
  display: block;
}
.file-upload-error {
  color: #F44336;
}
.map,
.map > div {
  height: 60vh;
}
.pac-container {
  z-index: 99999;
}
.map-popup-title {
  font-weight: bold;
  margin-bottom: 0.5em;
  font-size: 1.2em;
}
.map-popup-line-title {
  font-weight: bold;
}
.map-popup-icon {
  color: #283593;
  float: left;
}
.map-popup-info {
  margin-left: 30px;
}
.map-popup-link {
  display: block;
  color: #689F38;
  margin-left: 32px;
  font-weight: bold;
  text-decoration: underline;
}
.map-popup-divider {
  margin: 10px 0;
  border-bottom: 1px solid #d0d0d0;
}
.form-location-map-line {
  display: flex;
  align-items: center;
}
.form-location-map-line :first-child {
  flex: 1;
}
.form-location-map-line > :not(:first-child) {
  margin-left: 1em;
}
.form-location-map-line flat-button > span {
  margin: 0;
}
.form-location-map-line span.action > i {
  margin: 0;
}
form form-location-map .validation-error {
  margin-top: 0;
}
.main-logo {
  background: url(/static/5943319444bf6847c93f50b88e2cba0b.png) no-repeat 50% 0%;
  background-size: 100%;
  max-width: 667px;
}
#header-logo {
  background: url(/static/52ae60b93b787a68c62ef5f8822805c0.png) no-repeat 50% 50%;
}
.small-chip {
  display: inline-block;
  height: 30px;
  font-size: 10px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  line-height: 32px;
  padding: 0 8px;
  border-radius: 16px;
  background-color: #e4e4e4;
}
body .navigation-bar {
  background-color: white;
  padding: 8px 0;
  box-shadow: 0 0 4px #D0D0D0;
}
body .navigation-bar #header-left {
  color: #424242;
}
body .navigation-bar #header-left > :nth-child(2) > :first-child {
  font-size: 24px;
}
body .navigation-bar #header-left > :nth-child(2) > :nth-child(2) {
  font-size: 12px;
}
body .navigation-bar #header-logo {
  margin-left: 2rem;
}
body .navigation-bar ul li a {
  color: #424242 !important;
}
body .navigation-bar.vertical-menu-open ul.vertical-menu {
  background-color: white;
}
body .navigation-bar ul.vertical-menu li a i,
body ul.vertical-menu .nav-group-title > i {
  margin-right: 32px;
}
body .navigation-bar.vertical-menu-open ul.vertical-menu li .nav-group-title {
  color: #424242;
}
.nav-group-expanded .nav-group-title > span {
  border-bottom: 1px solid #D0D0D0;
  display: block;
  margin-left: 32px;
  padding-bottom: 12px;
}
body .navigation-bar.vertical-menu-open ul.vertical-menu li.nav-group-expanded {
  background-color: white;
}
.header-tabs {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  justify-content: center;
  margin-top: 0;
}
.header-tabs li {
  display: inline-block;
  cursor: pointer;
  color: rgba(0, 200, 83, 0.8);
  padding: 0.5em 1em;
}
.header-tabs li.active,
.header-tabs li.active.error {
  color: #00C853;
  border-bottom: 2px solid #00C853;
}
.header-tabs li.error {
  color: #F44336;
}
.header-tabs li.disabled {
  color: #9E9E9E;
  cursor: initial;
}
form.product-edit {
  max-width: none;
}
form.product-edit .header-tabs {
  margin-bottom: 1em;
}
form.product-edit .header-tabs li.color-code {
  color: #ff9800;
}
form.product-edit .header-tabs li.color-code.active {
  border-color: #ff9800;
}
form.product-edit .header-tabs li.color-code.validated {
  color: #4caf50;
}
form.product-edit .header-tabs li.color-code.validated.active:not(.error) {
  border-color: #4caf50;
}
form.product-edit .header-tabs li.color-code.rejected {
  color: #B71C1C;
}
form.product-edit .header-tabs li.color-code.rejected.active:not(.error) {
  border-color: #B71C1C;
}
form.product-edit .header-tabs li.color-code.disabled {
  color: #9E9E9E;
}
form.product-edit .header-tabs li.color-code.error {
  border: 2px solid #F44336;
}
form.product-edit .file-upload-section {
  display: flex;
  align-items: center;
}
form.product-edit .file-upload-section file-upload {
  flex: 1;
  margin-right: 1em;
}
form.product-edit .budgets-table {
  margin-top: 1em;
}
.inline-edit-table {
  border-collapse: separate;
  background-color: white;
}
.inline-edit-table > thead {
  border: none;
}
.inline-edit-table > tbody > tr > td,
.inline-edit-table > thead > tr > th {
  padding: 5px;
  border: 1px solid #d0d0d0;
  border-radius: 0;
  min-width: 100px;
}
.inline-edit-table > tbody > tr > td:not(:first-child),
.inline-edit-table > thead > tr > th:not(:first-child) {
  border-left: none;
}
.inline-edit-table > tbody > tr > td.wrap-col,
.inline-edit-table > thead > tr > th.wrap-col {
  min-width: 1px;
}
.inline-edit-table > thead > tr > th {
  border-bottom: none;
}
.inline-edit-table > tbody > tr:not(:last-child) > td {
  border-bottom: none;
}
.inline-edit-table th.is-sorted {
  cursor: pointer;
}
.inline-edit-table .header-line {
  display: flex;
  align-items: center;
}
.inline-edit-table .header-line > :nth-child(2) {
  margin-left: 5px;
}
.inline-edit-table .validation-errors {
  border-bottom: 1px solid #d0d0d0;
}
.inline-edit-table .validation-errors td {
  padding: 0;
  border-top: none;
}
.inline-edit-table .validation-errors td > div {
  padding: 0px 5px 5px 5px;
  color: #F44336;
}
.inline-edit-table .input-field {
  margin-top: 0;
}
.inline-edit-table .input-field label {
  top: 0;
  min-height: 1px;
}
.inline-edit-table .form-autocomplete-select {
  margin: 0;
}
.inline-edit-table .form-autocomplete-select .dropdown-content li {
  min-height: 0;
}
.inline-edit-table .form-autocomplete-select .dropdown-content li > a,
.inline-edit-table .form-autocomplete-select .dropdown-content li > span {
  padding: 4px;
}
.inline-edit-table .form-autocomplete-select input[type="text"]:not(.firebaseui-input):not(.firebaseui-input-invalid) {
  width: calc(100% - 24px);
}
.inline-edit-table .select-wrapper input.select-dropdown {
  height: 1.5em;
  margin: 0;
}
.inline-edit-table .input-field input[type="text"]:focus:not([readonly]),
.inline-edit-table .input-field textarea.materialize-textarea:focus:not([readonly]),
.inline-edit-table .input-field input[type="text"].valid,
.inline-edit-table input[type="text"].invalid {
  border-bottom: none;
  box-shadow: none;
}
.inline-edit-table .input-field input[type="text"]:focus:not([readonly]) + label,
.inline-edit-table .input-field textarea.materialize-textarea:focus:not([readonly]) + label {
  display: none;
}
.inline-edit-table input[type="text"].invalid + label::after {
  display: none;
}
.inline-edit-table input[type="text"]:not(.firebaseui-input):not(.firebaseui-input-invalid) {
  height: 1.5em;
  line-height: 1.5em;
  margin: 0;
  border: none;
}
.inline-edit-table form-select-search label.active,
.inline-edit-table form-text-input label.active,
.inline-edit-table form-textarea label.active,
.inline-edit-table form-location-map label.active,
.inline-edit-table form-date-input label.active {
  display: none;
}
.inline-edit-table .form-autocomplete-select .autocomplete-content {
  left: -5px;
  margin-top: 1.5rem;
}
.inline-edit-table .form-autocomplete-select i.clear {
  line-height: 1.5rem;
}
.inline-edit-table .form-autocomplete-select i.left {
  margin-top: 0;
}
.inline-edit-table .required-select {
  display: block;
  width: 50px;
}
.inline-edit-table .input-field textarea.materialize-textarea {
  margin: 0;
  padding: 0;
  border: none;
  min-height: 1rem;
  transition: none;
}
.inline-edit-table form-location-map span.action {
  line-height: 24px;
}
.inline-edit-table-with-actions > thead > tr > th:last-child {
  border: none;
  min-width: initial;
  width: 1px;
}
.inline-edit-table-with-actions > tbody > tr > td:last-child {
  min-width: initial;
}
i.action-disabled {
  color: #9E9E9E;
}
.import-entities.go-back {
  color: #9E9E9E;
  display: block;
  margin-bottom: 1em;
  cursor: pointer;
}
.import-entities .description {
  margin-bottom: 1em;
}
.import-entities .download-template .error {
  color: #F44336;
  margin-left: 32px;
  margin-bottom: 0.5em;
}
.import-entities .success {
  color: #4caf50;
  margin-top: 1em;
}
.import-entities .import-errors {
  margin-left: 32px;
}
.import-entities .import-errors > span,
.import-entities .import-errors td:last-child {
  color: #F44336;
}
.import-entities loading-indicator {
  line-height: 36px;
}
.import-entities loading-indicator > span {
  font-size: 1em;
}
.import-entities .preloader-wrapper.small {
  width: 24px;
  height: 24px;
}
.import-entities .text-with-icon > i.left,
.import-entities.text-with-icon > i.left {
  margin-right: 10px;
}
.import-entities .text-with-icon > span,
.import-entities.text-with-icon > span {
  line-height: 26px;
}
.suggested-business-line-form h3 {
  font-size: 1.5rem;
  font-weight: 300;
  color: #283593;
}
.suggested-business-line-form h4 {
  font-size: 1rem;
  font-weight: 200;
  margin-bottom: 42px;
}
.suggested-business-line-form p {
  font-size: 1.3rem;
  font-weight: 350;
}
.product-files-item {
  display: flex;
  margin-bottom: 1em;
  padding: 0.5em;
  border: 1px solid #d0d0d0;
}
.product-files-item > .remove {
  margin-right: 1em;
}
.product-files-item i {
  margin-right: 5px;
}
.product-files-item img {
  max-width: 200px;
}
.product-files-item a {
  display: flex;
  align-items: center;
  color: #00C853;
  min-height: 36px;
}
.product-files-item .file-info {
  color: #9E9E9E;
  font-size: 0.8rem;
}
product-checklist .material-icons.manager {
  color: #ff5722;
}
product-checklist .material-icons.coordinator {
  color: #2196f3;
}
product-checklist .checklist-tpp-notice {
  font-weight: bold;
  margin-bottom: 1em;
}
product-checklist .checklist-legend {
  color: #9E9E9E;
  margin-left: 1em;
  margin-bottom: 2em;
}
product-checklist .checklist-legend .legend-title {
  margin-bottom: 0.5em;
  margin-left: 0;
}
product-checklist .checklist-legend > div {
  display: flex;
  align-items: center;
  margin-left: 1em;
  margin-bottom: 5px;
}
product-checklist .checklist-legend > div > .material-icons {
  margin-left: -2px;
  opacity: 0.7;
}
product-checklist .checklist-legend > div .declined {
  margin: 0 0.5em 0 1em;
}
product-checklist .checklist-legend > div > :first-child {
  margin-right: 0.5rem;
}
product-checklist .checklist-legend .state-container {
  margin: 0;
  cursor: initial;
  opacity: 0.7;
}
product-checklist .checklist-header {
  display: flex;
  align-items: center;
}
product-checklist .checklist-header > i {
  margin: 1rem 1rem 0 1rem;
  width: 20px;
}
product-checklist .checklist-header > i:first-child {
  margin-right: 0;
}
product-checklist .checklist-item {
  display: flex;
  align-items: center;
}
product-checklist .state-container {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  height: 20px;
  width: 20px;
  min-width: 20px;
  border-radius: 2px;
  border: 2px solid #5a5a5a;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1em;
}
product-checklist .state-container:first-child {
  margin-right: 0;
}
product-checklist .state-container.approved {
  color: white;
  background-color: #4caf50;
  border: none;
}
product-checklist .state-container.declined {
  color: white;
  background-color: #B71C1C;
  border: none;
}
product-checklist .state-container > i {
  font-size: 18px;
}
.designing-page-buttons {
  margin-right: 16px;
}
.select-experimental-design {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  overflow-x: auto;
}
.select-experimental-design > div {
  cursor: pointer;
  margin: 0 0.25em 1em;
  padding: 0.5em;
  border: 2px solid #eee;
  border-radius: 8px;
  min-height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.select-experimental-design > div.selected {
  border-color: #00C853;
}
.select-experimental-design > div .design-title {
  text-align: center;
}
.select-experimental-design > div .design-row {
  display: flex;
  margin: 8px 0;
}
.select-experimental-design > div .design-row > div {
  width: 64px;
  height: 64px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.select-experimental-design > div .design-row > div:not(:last-child) {
  margin-right: 8px;
}
.select-experimental-design > div .design-row > div.current {
  background-color: #ACBC4E;
}
.select-experimental-design > div .design-row > div.innovation {
  background-color: #478F46;
}
.select-experimental-design > div .design-more {
  color: #9E9E9E;
}
.development-headers {
  display: flex;
  border-width: 0 0 0 1px;
  margin: 0;
}
.development-headers,
.development-headers > li {
  border-style: solid;
  border-color: #d0d0d0;
}
.development-headers > li {
  padding: 0.5em 1em;
  border-width: 1px 1px 1px 0;
  cursor: pointer;
  color: #7986CB;
}
.development-headers > li.active,
.development-headers > li.active.error {
  border-bottom: 0;
  color: #283593;
}
.development-headers > li.error {
  color: #F44336;
}
.development-headers span.action {
  line-height: 1em;
}
.development-headers > li:last-child {
  flex: 1;
  cursor: inherit;
  border-width: 0 0 1px 0;
  padding-left: 0.5em;
}
.development {
  border-style: solid;
  border-color: #d0d0d0;
  border-width: 0 1px 1px 1px;
  padding: 1em;
}
.development .form-section-title {
  font-size: 1em;
}
.development .development-record-table-container {
  overflow-x: auto;
}
.development .development-record-table > tbody > tr.even-row > td {
  background-color: #F2F2F2;
}
.development .development-record-table > tbody > tr.even-row > td:first-child,
.development .development-record-table > tbody > tr.even-row > td:nth-child(2) {
  background-color: #F2F2F2;
}
.development .development-record-table > tbody > tr.disabled-row > td {
  background-color: #DBDBD9;
}
.development .development-record-table > tbody > tr.disabled-row > td:first-child,
.development .development-record-table > tbody > tr.disabled-row > td:nth-child(2) {
  background-color: #DBDBD9;
}
.development .development-record-table > thead > tr > th:first-child,
.development .development-record-table > tbody > tr > td:first-child,
.development .development-record-table > thead > tr > th:nth-child(2),
.development .development-record-table > tbody > tr > td:nth-child(2) {
  position: sticky;
  background-color: white;
  border-radius: 0;
  z-index: 1;
  box-shadow: 1px 0 2px 0 #d0d0d0;
}
.development .development-record-table > thead > tr > th:first-child,
.development .development-record-table > tbody > tr > td:first-child {
  left: 0;
  width: 100px;
  clip-path: inset(0 0 -6px 0);
}
.development .development-record-table > thead > tr > th:nth-child(2),
.development .development-record-table > tbody > tr > td:nth-child(2) {
  left: 100px;
  clip-path: inset(0 -6px -6px 0);
}
.development span.action {
  line-height: 24px;
  margin-right: 0;
}
.development-remove-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1em;
}
.fixed-header {
  position: sticky;
  top: -1px;
  z-index: 2;
  background-color: white;
}
.save-error {
  color: #F44336;
  margin-bottom: 1em;
  letter-spacing: normal;
}
.save-success {
  color: #4caf50;
  margin-bottom: 1em;
  letter-spacing: normal;
}
.phase-1-upload-pictures {
  margin: 1em 0;
  display: block;
}
.title-with-message-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.cant-save-warning {
  padding: 0.5em;
  border: 1px solid #F44336;
  color: #F44336;
}
.steps-container {
  display: flex;
  align-items: stretch;
}
.phase-steps {
  position: sticky;
  align-self: flex-start;
  top: 100px;
}
.phase-steps > li {
  cursor: pointer;
  padding: 0 0.5em;
  margin: 1em 0.5em;
  color: #7986CB;
}
.phase-steps > li.active,
.phase-steps > li.active.error {
  color: #283593;
  border-left: 2px solid #283593;
}
.phase-steps > li.error {
  color: #F44336;
}
.phase-steps > li.completed::after {
  content: ' ✓';
}
.step-help-text {
  color: #9E9E9E;
  margin-bottom: 1em;
  font-size: 0.8em;
}
.phase-content {
  flex: 1;
  padding-left: 1em;
  min-width: 0;
  border-left: 1px solid #d0d0d0;
  overflow-x: auto;
}
.add-factor,
.add-budget {
  display: block;
}
.business-line-sample-descrs {
  margin-top: 1em;
}
.development-record-table th {
  position: relative;
}
.development-record-table .tooltip,
.development-record-table .arrow-top {
  display: none;
}
.development-record-table th:hover .tooltip,
.development-record-table th.show-help-text .tooltip {
  margin-top: 10px;
  display: block;
  position: absolute;
  background-color: white;
  z-index: 2;
  border: 1px solid #d0d0d0;
  width: 200px;
  font-weight: normal;
  padding: 0.5em;
}
.development-record-table th:hover .arrow-top,
.development-record-table th.show-help-text .arrow-top {
  margin-top: -2px;
  margin-left: 0.5em;
  position: absolute;
  display: block;
  content: " ";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 12px 12px 12px;
  border-color: transparent transparent #d0d0d0 transparent;
}
.development-record-table th:hover .arrow-top::after,
.development-record-table th.show-help-text .arrow-top::after {
  display: block;
  content: " ";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 12px 12px 12px;
  border-color: transparent transparent white transparent;
  position: absolute;
  left: -12px;
  top: 1px;
  z-index: 3;
}
.development-record-table-factor-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.development-record-table-factor-header > i {
  margin-left: 10px;
  font-size: 20px;
}
.inline-edit-table-select {
  -webkit-appearance: none;
  appearance: none;
  height: 1.5em;
  padding: 0;
  border: none;
  cursor: pointer;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E") no-repeat 100% 0%;
  display: block;
}
.inline-edit-table-select.empty {
  color: #9E9E9E;
}
.inline-edit-table-select:focus {
  outline: none;
}
.business-line-question {
  display: grid;
  grid-template-columns: 4fr 1fr;
  column-gap: 8px;
  align-items: center;
}
.inline-edit-table-select::-ms-expand {
  display: none;
}
.tpp-results-chart {
  width: 600px;
}
.actions-dropdown {
  position: relative;
  width: 130px;
}
.actions-dropdown :first-child {
  width: 100%;
}
.choice-wrapper {
  margin-bottom: 16px;
}
.comment-item {
  margin-bottom: 1em;
}
.comment-header {
  font-size: 0.8rem;
  color: #9E9E9E;
}
.comment-content {
  display: flex;
  align-items: flex-start;
  border: 1px solid #d0d0d0;
  padding: 0.5em;
}
.comment-content > flat-button {
  height: 24px;
}
.comment-content > flat-button > span.action > div {
  margin-left: 0;
}
.comment-content > flat-button > span.action > i {
  margin-right: 0;
}
.comment-content > flat-button > span {
  margin-right: 0;
  line-height: 24px;
}
.comment-text {
  flex: 1;
  white-space: pre-wrap;
}
.new-comment {
  border: 1px solid #d0d0d0;
  margin-bottom: 1em;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
}
.new-comment-send {
  align-self: end;
}
.container.dashboard-container {
  min-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.container.dashboard-container > * {
  flex-shrink: 0;
}
.dashboard-loading-container {
  flex: 1;
  display: flex;
  justify-content: center;
}
.dashboard-carousel {
  position: relative;
  min-height: 500px;
  overflow: hidden;
}
.dashboard-carousel-images {
  display: flex;
  transition: transform 0.5s linear;
}
.dashboard-carousel-images > div {
  display: flex;
  flex-direction: column;
  height: 500px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-width: 100%;
}
.dashboard-charts-filter-row {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 0 1em;
  flex-shrink: 0;
  margin-top: 24px;
}
.dashboard-charts-filter-row list-filters .filters {
  background-color: #D9DDCB;
}
.dashboard-title-image-text {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 50px;
}
.dashboard-title-image-text-description {
  font-size: 26px;
}
.dashboard-carousel-pager {
  position: absolute;
  bottom: 46px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}
.dashboard-carousel-pager > div {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.6);
  margin-right: 18px;
  cursor: pointer;
}
.dashboard-carousel-pager > div.selected {
  background-color: white;
}
.dashboard-main-description {
  width: 900px;
  margin: 46px auto 80px auto;
}
.dashboard-main-description strong {
  color: #689F38;
  font-weight: bold;
}
.dashboard-main-description h2 {
  font-size: 1.6rem;
}
.dashboard-main-description ul:not(.browser-default) li {
  list-style-type: disc;
}
.dashboard-main-description ul:not(.browser-default),
.dashboard-main-description ol:not(.browser-default) {
  padding-left: 2em;
}
.dashboard-innovations {
  background-color: #F2F3EB;
  background-image: url(/static/4e573010f6c205f5e9f1072b54f54dbb.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 100px;
  padding-bottom: 168px;
}
.dashboard-categories {
  display: flex;
  flex-wrap: wrap;
  max-width: 900px;
  margin: 0 auto;
}
.dashboard-category-picture {
  margin: 5px;
  width: 215px;
  min-width: 215px;
  height: 215px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  position: relative;
  color: white;
  line-height: 1.2em;
}
.dashboard-category-picture::before {
  display: block;
  content: "";
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.dashboard-category-title {
  font-weight: bold;
  z-index: 1;
  font-size: 1.2em;
}
.dashboard-category-action {
  overflow: hidden;
  z-index: 1;
  background-color: #00A2BE;
  border-radius: 24px;
  padding: 10px 18px;
  text-transform: uppercase;
  color: white;
  cursor: pointer;
}
.dashboard-statistics {
  padding: 100px 0;
  background-color: #E1E1E1;
}
.dashboard-statistics list-filters .filters {
  background-color: #D9DDCB;
}
.dashboard-statistics-inner {
  max-width: 900px;
  margin: 0 auto;
}
.dashboard-statistics-line {
  display: flex;
}
.dashboard-statistics-line:not(:last-child) {
  margin-bottom: 50px;
}
.dashboard-statistic-box {
  flex: 1;
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 24px #CCC;
}
.dashboard-statistic-box:not(:last-child) {
  margin-right: 10px;
}
.dashboard-statistic-title {
  color: #424242;
  font-size: 25px;
  font-weight: 600;
  margin: 42px auto 0;
  line-height: 53px;
  height: 53px;
}
.dashboard-stat-icon-by-category {
  background-position: 0% 50%;
  background-repeat: no-repeat;
  background-image: url(/static/8d3c819d86b695a5f13ecf140f3b39c1.png);
  width: 237px;
  height: 116px;
}
.dashboard-stat-icon-by-phase {
  background-position: 0% 50%;
  background-repeat: no-repeat;
  background-image: url(/static/d4a78c9e38465b2b2126f8931d416430.png);
  width: 132px;
  height: 116px;
}
.dashboard-statistic-kpi {
  color: #84973A;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 64px;
  font-weight: 600;
}
.dashboard-chart-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.dashboard-chart {
  flex: 1;
  min-height: 350px;
  margin: 32px 60px 0;
}
.dashboard-partners {
  padding-top: 110px;
  padding-bottom: 110px;
}
.dashboard-partners-icons {
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.partner-img {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  width: 170px;
  height: 76px;
}
.dashboard-goals {
  color: #F4F4F4;
  background-color: #5F7800;
  padding-top: 84px;
  padding-bottom: 156px;
  text-align: center;
}
.dashboard-goals-title {
  font-size: 35px;
  padding-bottom: 2em;
}
.dashboard-goals-items {
  display: flex;
  justify-content: space-around;
  max-width: 900px;
  margin: 0 auto;
}
.dashboard-goals-items > div {
  width: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 50px;
  background-color: white;
  color: #414141;
}
.un-goal-picture {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100%;
  width: 150px;
  height: 150px;
}
.un-goal-picture-1 {
  background-image: url(/static/f0c2e3d43c49dd2656b39fa2498fb6e6.png);
}
.un-goal-picture-2 {
  background-image: url(/static/8b749634ec647be36518bbe0e9244ddd.png);
}
.un-goal-picture-13 {
  background-image: url(/static/6bdea1eaa42f17bef3b1faf2ae19f55a.png);
}
.un-goal-title {
  text-transform: uppercase;
}
.un-goal-description {
  font-size: 0.8em;
  margin-top: 1em;
}
.dashboard-team {
  color: #424242;
  background-color: #F2F3EB;
  background-image: url(/static/01bbdabefdb60abafcf4b99104ced92f.jpg);
  background-size: cover;
  background-position: 50% 0%;
  background-repeat: no-repeat;
  padding-top: 84px;
  padding-bottom: 156px;
}
.dashboard-team-title {
  font-size: 35px;
  text-align: center;
  padding-bottom: 2em;
}
.dashboard-team-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.dashboard-team-items > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 50px;
  width: 362px;
}
.dashboard-team-picture {
  width: 200px;
  height: 200px;
  box-shadow: 0px 0px 24px #CCC;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}
.dashboard-team-name {
  font-size: 1.2em;
  font-weight: bold;
  margin: 0.5em 0;
}
.dashboard-footer-container {
  background-color: #424242;
  color: #F4F4F4;
  padding-top: 60px;
  padding-bottom: 80px;
}
.dashboard-footer {
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.dashboard-footer > :last-child {
  text-align: right;
}
.dashboard-footer-title-1 {
  font-size: 21px;
  margin-bottom: 1.5em;
}
.dashboard-footer-title-2 {
  font-size: 16px;
  margin-bottom: 1.5em;
}
.dashboard-footer-address {
  font-size: 12px;
}
.dashboard-footer-contact {
  border-radius: 8px;
  padding: 0.5em 1.5em;
  background-color: #84973A;
  font-size: 21px;
  color: #F4F4F4;
  display: inline-block;
}
.dashboard-map-legend {
  background-color: white;
  padding: 0.5em;
  margin: 10px;
  font-size: 14px;
}
.dashboard-map-legend-title {
  color: #424242;
  font-weight: 600;
  margin-bottom: 0.5em;
}
.dashboard-map-legend-items > div {
  display: flex;
  align-items: center;
}
.dashboard-map-legend-items > div > :first-child {
  width: 36px;
  height: 12px;
  border: 1px solid #424242;
  margin-right: 5px;
}
.dashboard-home-btn {
  display: inline-flex;
  align-items: center;
  margin: 2em 1em 1em;
  background-color: #00A2BE;
  border-radius: 24px;
  padding: 10px 18px;
  text-transform: uppercase;
  color: white;
  cursor: pointer;
  box-shadow: 0 1px 4px #999;
}
.dashboard-home-btn > i {
  margin-right: 6px;
  margin-left: -6px;
}
.container.dashboard-details-container {
  min-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.dashboard-title-row {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 0 1em;
  flex-shrink: 0;
}
.dashboard-title-row list-filters .filters {
  background-color: #D9DDCB;
}
.dashboard-title {
  color: #424242;
  font-size: 40px;
}
.dashboard-panel-loading {
  opacity: 0.5;
  pointer-events: none;
}
.dashboard-detail-section-title {
  color: #424242;
  font-weight: bold;
  font-size: 1.2em;
  margin: 1.5rem 1rem 0.5rem;
}
.dashboard-form-select {
  margin: 1.5rem 1rem 0.5rem;
  width: 360px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 36px;
}
.dashboard-no-innovations {
  color: #9E9E9E;
  text-align: center;
  margin-top: 2em;
}
.dashboard-table {
  color: #424242;
}
.dashboard-table > tbody > tr > td {
  overflow: hidden;
  vertical-align: top;
}
.dashboard-table > tbody > tr > td:first-child {
  padding-left: 20px;
}
.dashboard-table > tbody > tr > td:last-child {
  padding-right: 20px;
}
.dashboard-table tr.even {
  background-color: #EEE;
}
.dashboard-table tr.expanded,
.dashboard-table tr.even.expanded {
  background-color: rgba(95, 120, 0, 0.2);
}
.dashboard-main-cell {
  display: flex;
}
.dashboard-table-picture {
  min-width: 140px;
  width: 140px;
  height: 140px;
  margin-right: 10px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(/static/ec878ca1f140524b14b59c32470bda34.png);
}
.dashboard-table-details {
  display: flex;
  flex-direction: column;
  padding: 0.5em 0;
  min-width: 180px;
}
.dashboard-main-cell-title {
  font-weight: bold;
  font-size: 1.1em;
  flex: 1;
}
.dashboard-main-cell-highlight {
  font-weight: 500;
}
.dashboard-main-cell-tags {
  display: flex;
}
.dashboard-main-cell-tags > :first-child {
  margin-right: 1ch;
}
.dashboard-cell-title {
  font-weight: bold;
}
.dashboard-supervisor {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 150px;
}
.dashboard-supervisor a {
  cursor: pointer;
  text-decoration: underline;
  color: #00A2BE;
}
.dashboard-supervisor-picture {
  width: 75px;
  height: 75px;
  border-radius: 40px;
  border: 1px solid #999;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(/static/95e8857ab294fbe5f004dde95d0e9aa7.svg);
}
.dashboard-small-map {
  width: 140px;
  height: 140px;
}
.dashboard-actions-cell > div {
  display: flex;
  align-items: center;
  min-height: 1px;
  height: 140px;
  justify-content: flex-end;
}
.dashboard-description {
  white-space: pre-wrap;
  max-height: 9.6em;
  overflow: hidden;
  line-height: 1.2;
}
.expanded .dashboard-description {
  max-height: unset;
}
.dashboard-tag {
  display: inline-block;
  border-radius: 6px;
  padding: 0 5px;
  margin-bottom: 5px;
  background-color: #00A2BE;
  color: white;
  font-weight: bold;
}
.dashboard-table-actions-header {
  width: 118px;
}
.dashboard-table-actions-header.authorized {
  width: 150px;
}
.dashboard-action {
  color: #00A2BE;
  cursor: pointer;
}
.dashboard-action:not(:last-child) {
  margin-right: 5px;
}
.dashboard-action.dashboard-action-disabled {
  color: #9E9E9E;
}
.dashboard-details-pdf-link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1em;
  color: #00A2BE;
  cursor: pointer;
}
.dashboard-details-pdf-link > i {
  margin-right: 8px;
}
.dashboard-details-box {
  background-color: white;
  padding: 1em;
  border-radius: 4px;
  margin: 0 0.5em;
}
.dashboard-details-box.single-row {
  margin-left: 0;
  margin-bottom: 1em;
}
.dashboard-details-box-title {
  color: #5F7800;
  text-transform: uppercase;
  font-weight: bold;
}
.dashboard-detail-row {
  display: flex;
  margin: 1em 0;
}
.dashboard-detail-row > :first-child .dashboard-details-box,
.dashboard-detail-row > :first-child.dashboard-details-box {
  width: 140px;
  min-width: 140px;
  margin-left: 0;
}
.dashboard-detail-row > :not(:first-child) {
  flex: 1;
}
.dashboard-detail-country-icon {
  background: url(/static/2973955a372481dd274f53c43fbddb00.png);
  width: 22px;
  height: 39px;
}
.dashboard-details-country {
  display: flex;
  justify-content: space-between;
}
.dashboard-details-box-title-container {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
}
.dashboard-details-box-title-container > :first-child {
  margin-right: 1em;
}
.dashboard-detail-crops-icon {
  background: url(/static/2a53536623bc799c8404459c12735561.png);
  width: 35px;
  height: 33px;
}
.dashboard-detail-partners-icon {
  background: url(/static/e558f0f3755f5e882415c28b85b0a84b.png);
  width: 34px;
  height: 37px;
}
.dashboard-detail-weather-icon {
  background: url(/static/ff6d754e728bc28692efc35278e02843.png);
  width: 59px;
  height: 52px;
}
.dashboard-detail-list-item {
  font-weight: 500;
}
.dashboard-details-box-para {
  margin-top: 1.5em;
  font-size: 0.8em;
}
.dashboard-details-columns {
  display: flex;
}
.dashboard-details-columns > :first-child {
  flex-basis: 250px;
  flex-grow: 2;
}
.dashboard-details-columns > :last-child {
  flex: 2;
}
.dashboard-details-columns > :last-child .single-row {
  margin-left: 0.5em;
}
.phases-weather-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  margin: 24px 0;
}
.col-fixed-height {
  height: 200px !important;
}
.dashboard-details-pictures {
  margin-top: 32px;
}
.dashboard-details-pictures > div {
  display: inline-block;
  width: 320px;
  height: 180px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-bottom: 32px;
  margin-right: 32px;
}
.dashboard-details-weather-options {
  display: flex;
  justify-content: space-between;
}
.dashboard-details-radio-group > div {
  margin-top: 1em;
}
.dashboard-details-radio-group label {
  color: #424242;
}
.dashboard-details-radio-group [type="radio"]:checked + label::after {
  border: 2px solid #00A2BE;
  background-color: #00A2BE;
  padding: 2px;
  background-clip: content-box;
}
.dashboard-details-radio-group [type="radio"]:not(:checked) + label::before,
.dashboard-details-radio-group [type="radio"]:not(:checked) + label::after {
  border: 2px solid #424242;
}
list-filters.dashboard-weather-filters .filters {
  background-color: rgba(0, 162, 190, 0.3);
}
.dashboard-details-factors-table {
  border-collapse: separate;
  border-spacing: 0 0.5em;
}
.dashboard-details-factors-table thead {
  border-bottom: none;
}
.dashboard-details-factors-table th {
  font-weight: bold;
  color: #424242;
  padding: 5px;
}
.dashboard-details-factors-table td {
  background-color: #F4F4F4;
  padding: 5px;
}
.dashboard-details-phases {
  display: flex;
  margin-top: 18px;
}
.dashboard-details-phases > div {
  min-width: 80px;
}
.dashboard-details-phases > div > .highlighted {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.dashboard-details-today {
  font-size: 10px;
  color: white;
  background-color: #00A2BE;
  position: absolute;
  top: -12px;
  border-radius: 2px;
  padding: 0 2px;
}
:not(.highlighted) > .dashboard-details-today {
  display: none;
}
.dashboard-details-phase-item {
  border-left: 1px solid #898989;
  height: 32px;
  width: 100%;
  display: flex;
  align-items: center;
  border-right: 1px solid #898989;
}
.dashboard-details-phase-item > div {
  background-color: rgba(95, 120, 0, 0.3);
  padding: 0 0.5em;
  font-size: 10px;
  flex: 1;
  white-space: nowrap;
}
.dashboard-details-phases > :first-child .dashboard-details-phase-item {
  border-left-width: 2px;
}
.dashboard-details-phases > :last-child .dashboard-details-phase-item {
  border-right-width: 2px;
}
.highlighted .dashboard-details-phase-item {
  border-left-color: #00A2BE;
  border-right-color: #00A2BE;
  color: white;
  font-weight: bold;
}
.highlighted .dashboard-details-phase-item > div {
  background-color: #00A2BE;
}
.dashboard-details-phase-dates {
  font-size: 10px;
  width: 0;
  white-space: nowrap;
}
.dashboard-details-phase-dates :last-child {
  font-weight: bold;
}
.dashboard-contact-actions {
  margin-top: 2em;
  border-top: 1px solid rgba(160, 160, 160, 0.2);
  padding-top: 1.5em;
}
.dashboard-contact-success {
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  color: #4caf50;
  font-weight: 300;
}
.dashboard-contact-success > i {
  margin-right: 0.5rem;
  font-size: 2rem;
}
.form-edit-help-container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  position: relative;
}
.form-edit-help-container.textarea-help {
  align-items: center;
}
.form-edit-help-container.select-help {
  align-items: center;
}
.form-edit-help-container.select-help > .material-icons {
  top: 0;
}
.form-edit-help-container > :first-child {
  flex: 1;
  margin-right: 1em;
}
.form-edit-help-container > .material-icons {
  cursor: pointer;
  top: 7px;
  position: relative;
}
.form-edit-help-container .form-edit-help {
  display: none;
  position: absolute;
  background-color: white;
  right: 24px;
  border: 1px solid #d0d0d0;
  max-width: 400px;
  padding: 0.5em;
  z-index: 2;
  top: 24px;
}
.form-edit-help-container .form-edit-help > p {
  margin: 1em 0;
}
.form-edit-help-container .form-edit-help > div {
  margin-bottom: 1em;
}
.form-edit-help-container .form-edit-help-upward {
  top: auto;
  bottom: 24px;
}
.form-edit-help-container > .material-icons:hover + .form-edit-help,
.form-edit-help-container .form-edit-help:hover {
  display: block;
}
.form-section-help {
  color: #9E9E9E;
  margin-bottom: 1em;
  font-size: 0.8em;
}
.form-section-help > div {
  margin-bottom: 1em;
}
.documentation-file i {
  margin-right: 5px;
}
.documentation-file a {
  display: flex;
  align-items: center;
  color: #00C853;
  min-height: 36px;
}
